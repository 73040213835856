import { createRouter, createWebHistory } from 'vue-router';
import DynamicMainComponent from '@/components/DynamicMainComponent.vue'; // Новый динамический компонент

// Асинхронная загрузка компонентов
const MaterialsComponent = () => import('@/components/MaterialsComponent.vue');
const CollectionsComponent = () => import('@/components/CollectionsComponent.vue');
const CollectionComponent = () => import('@/components/CollectionComponent.vue');
const ChannelsComponent = () => import('@/components/ChannelsComponent.vue');
const ChannelComponent = () => import('@/components/ChannelComponent.vue');
const ArticleComponent = () => import('@/components/ArticleComponent.vue');
const SearchComponent = () => import('@/components/SearchComponent.vue');
const AboutComponent = () => import('@/components/AboutComponent.vue');
const HistoryComponent = () => import('@/components/HistoryComponent.vue');

// Базовые маршруты
let routes = [
  {
    path: '/',
    name: 'Main',
    component: DynamicMainComponent, // Используем динамический компонент
    meta: {
      title: 'Главная или Канал',
      keepAlive: true
    }
  },
  {
    path: '/about',
    name: 'About',
    component: AboutComponent,
    meta: {
      title: 'О проекте'
    }
  },
  {
    path: '/history',
    name: 'History',
    component: HistoryComponent,
    meta: {
      title: 'История просмотров'
    }
  },  
  {
    path: '/materials',
    name: 'Materials',
    component: MaterialsComponent,
    meta: {
      title: 'Материалы'
    }
  },
  {
    path: '/collections',
    name: 'Collections',
    component: CollectionsComponent,
    meta: {
      title: 'Курсы и плейлисты'
    }
  },
  {
    path: '/collection/:id',
    name: 'Collection',
    component: CollectionComponent,
    meta: {
      title: 'Информация о курсе или плейлисте'
    }
  },
  {
    path: '/channels',
    name: 'Channels',
    component: ChannelsComponent,
    meta: {
      title: 'Каналы'
    }
  },
  {
    path: '/article/:id',
    name: 'Article',
    component: ArticleComponent,
    meta: {
      title: 'Материал'
    }
  },
  {
    path: '/channel/:id', // Маршрут с параметром id
    name: 'Channel',
    component: ChannelComponent,
    props: true, // Это позволяет передавать параметры как свойства (props)
    meta: {
      title: 'Информация о канале',
      keepAlive: true
    }
  },
  {
    path: '/search/:query/:type/:contextId?',
    name: 'Search',
    component: SearchComponent,
    props: (route) => ({
      query: route.params.query,
      type: route.params.type,
      contextId: route.params.contextId || null
    })
  }
];

// Создаем роутер
export const router = createRouter({
  history: createWebHistory('/app/'),
  routes
});
