import axiosConfig from '@/lib/axiosConfig';
import { API_URL } from '@/config.js';
import store from '@/store'; // Import the store instance

const articleModule = {
  namespaced: true,
  state: {
    articleItem: null,
    articleTimecodeWords: [],
    relatedFragments: [], // Добавляем переменную для хранения списка фрагментов
  },
  mutations: {
    setArticleItem(state, article) {
      state.articleItem = article;
    },
    updateArticleItem(state, payload) {
      // Объединяем существующие свойства articleItem с новыми данными
      state.articleItem = { ...state.articleItem, ...payload };
      //console.log(state.articleItem);
    },
    setArticleTimecodeWords(state, words) {
      state.articleTimecodeWords = words;
    },
    setRelatedFragments(state, fragments) { // Добавляем мутацию для relatedFragments
      state.relatedFragments = fragments;
    }    
  },
  actions: {
    fetchArticle({ commit }) {
      return axiosConfig
        .get(API_URL, {
          params: {
            action: 'get_article',
          },
        })
        .then(response => {
          const article = response.data.article;
          commit('setArticleItem', article);

          if (typeof article !== 'undefined' && article.channel_id) {
            store.dispatch('channel/fetchChannel', { channelId: article.channel_id });
          }
        })
        .catch(error => {
          console.error(error);
          throw error;
        });
    },
    fetchArticleTimecodeWords({ commit }, materialId) {
      return axiosConfig
        .get(API_URL, {
          params: {
            action: 'get_timecodewords',
            materialId: materialId
          }
        })
        .then(response => {
          commit('setArticleTimecodeWords', response.data.result);
        })
        .catch(error => {
          console.error('Error fetching article timecode words:', error);
          throw error;
        });
    },
    saveMaterialVideosSegments(_, { materialId, video }) {
      return axiosConfig
        .post(API_URL, {
          params: {
            action: 'save_material_videos_segments',
            materialId: materialId,
            video: video
          }
        })
        .then(response => {
          return response.data;
        })
        .catch(error => {
          console.error('Error saving word:', error);
          throw error;
        });
    },
    saveMaterialWords(_, { materialId, videoId, segmentStart, word }) {
      return axiosConfig
        .post(API_URL, {
          params: {
            action: 'save_timecode_word',
            materialId: materialId,
            videoId: videoId,
            segmentStart: segmentStart,
            word: word
          }
        })
        .then(response => {
          return response.data;
        })
        .catch(error => {
          console.error('Error saving word:', error);
          throw error;
        });
    },

    generateGoogleDoc(_, { materialId }) {
      return axiosConfig
        .post(API_URL, {
          params: {
            action: 'generate_google_doc',
            materialId: materialId
          }
        })
        .then(response => {
          return response.data;
        })
        .catch(error => {
          console.error('Error saving word:', error);
          throw error;
        });
    },    
    
    saveArticle({ state }) {
      const { articleItem } = state;
      return axiosConfig
        .post(API_URL, {
          params: {
            action: 'save_article',
            id: articleItem.id,
            articleContent: articleItem.content,
            articleSummary: articleItem.text_summary,
            articleChecklist: articleItem.text_checklist,
            articleTitle: articleItem.title,
            articleVideoUrl: articleItem.video_url,
            articleDescription: articleItem.description,
            articleDontConvertMedia: articleItem.dont_convert_media,
            templateId: articleItem.templateId,
            verticalAlign: articleItem.verticalAlign,
          },
        })
        .then(response => {
          return response.data;
        })
        .catch(error => {
          console.error(error);
          throw error;
        });
    },
    requestArticleAccess({ state }) {
      const { articleItem } = state;
      return axiosConfig
        .post(API_URL, {
          params: {
            action: 'request_article_access',
            articleID: articleItem.id,
          },
        })
        .then(response => {
          return response.data;
        })
        .catch(error => {
          console.error(error);
          throw error;
        });
    },

  updateMaterialStatus(_, { materialId, status }) {
    return axiosConfig
      .post(API_URL, {
        params: {
          action: 'update_material_status',
          materialId: materialId,
          status: status,  // Передаем статус
        },
      })
      .then(response => {
        return response.data;
      })
      .catch(error => {
        console.error('Error updating material status:', error);
        throw error;
      });
  },

  resetMaterial(_, { materialId, fullReset }) {
    return axiosConfig
      .post(API_URL, {
        params: {
          action: 'reset_material',
          materialId: materialId,
          fullReset: fullReset,  // Передаем статус
        },
      })
      .then(response => {
        return response.data;
      })
      .catch(error => {
        console.error('Error updating material status:', error);
        throw error;
      });
  },


    async generateNewVideo(_, { materialId, videoId, video, preview_mode }) {
      try {
        const response = await axiosConfig.post(`${API_URL}`, {
          params: {
            action: 'generate_new_video',
            materialId: materialId,
            videoId: videoId,
            preview_mode: preview_mode,
            video: video
          }
        });
        console.log('New video generated successfully:', response.data);
        return response.data; // Возвращаем данные с сервера
      } catch (error) {
        console.error('Error generating new video:', error);
        throw error;
      }
    },
    async fetchVideoStatus(_, { materialId, videoId }) {
      try {
        const response = await axiosConfig.get(`${API_URL}`, {
          params: {
            action: 'get_video_status',
            materialId: materialId,
            videoId: videoId
          }
        });
        return response.data;
      } catch (error) {
        console.error('Error fetching video status:', error);
        throw error;
      }
    },

    async fetchRelatedFragments({ commit }, { articleId, startTime }) {
      try {

        console.log(1);
        // Если есть фрагменты, запрос на получение их списка
        const fragmentsResponse = await axiosConfig.get(`${API_URL}`, {
          params: {
            action: 'get_related_fragments',
            articleId: articleId,
            startTime: startTime
          }
        });

        const relatedFragments = fragmentsResponse.data.fragments || [];
        commit('setRelatedFragments', relatedFragments);
      
      } catch (error) {
        console.error('Ошибка при получении фрагментов:', error);
        throw error;
      }
    }       

  },
  getters: {
    getArticleItem(state) {
      return state.articleItem;
    },
    getArticleTitle(state) {
      return state.articleItem ? state.articleItem.title : '';
    },
    getArticleContent(state) {
      return state.articleItem ? state.articleItem.content : '';
    },
    getArticleTimecodeWords(state) {
      return state.articleTimecodeWords;
    },
    getRelatedFragments(state){
      return state.relatedFragments;
    }
  },
};

export default articleModule;
