// transactionsModule.js
import axiosConfig from '@/lib/axiosConfig';
import { API_URL } from '@/config.js';

const transactionsModule = {
  namespaced: true,
  state: {
    transactions: [],
  },
  mutations: {
    setTransactions(state, transactions) {
      state.transactions = transactions;
    },
  },
  actions: {
    fetchTransactions({ commit }, userId) {
      return axiosConfig
        .get(API_URL, {
          params: {
            action: 'get_transactions',
            userId: userId
          }
        })
        .then(response => {
          commit('setTransactions', response.data.transactions);
          return response.data.transactions;
        })
        .catch(error => {
          console.error('Error fetching transactions:', error);
        });
    },
    refundTopup({ dispatch }, { userId, topupId }) {
      return axiosConfig
        .post(API_URL + '?action=refund_topup', {
          params: {
            userId: userId,
            topupId: topupId
          }
        })
        .then(response => {
          dispatch('fetchTransactions', userId); // Обновляем транзакции после возврата
          console.log('Пополнение возвращено:', response);
        })
        .catch(error => {
          console.error('Ошибка возврата пополнения:', error);
        });
    },
    deleteWithdrawal({ dispatch }, { userId, withdrawalId }) {
      return axiosConfig
        .post(API_URL + '?action=delete_withdrawal', {
          params: {
            userId: userId,
            withdrawalId: withdrawalId
          }
        })
        .then(response => {
          dispatch('fetchTransactions', userId); // Обновляем транзакции после удаления
          console.log('Списание удалено:', response);
        })
        .catch(error => {
          console.error('Ошибка удаления списания:', error);
        });
    },
  },
  getters: {
    getTransactions(state) {
      return state.transactions;
    }
  }
};

export default transactionsModule;
