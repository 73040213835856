<template>

<div class="container">
    <SideMenuComponent :isVisible="menuVisible" @toggleMenu="handleToggleMenu"/>


  <div class="content-layout">
    
<!--     <header class="" v-if="showHeader && configData.site_caption">
      <HeaderComponent />
    </header>
 -->
    <header class="" v-if="!showHeader && configData.site_caption">
      <HeaderChatbotsComponent />
    </header>


      <main>
        

      <router-view v-slot="{ Component }">
        <keep-alive>
          <component :is="Component" :key="$route.fullPath"></component>
        </keep-alive>
      </router-view>

        <!-- <router-view></router-view> -->
        <button 
          v-if="buttonVisible" 
          @click="isModalVisible = true" 
          class="btn btn-danger"
          :style="{ position: 'absolute', top: buttonPosition.top, left: buttonPosition.left }"
        >
          Сообщить об ошибке
        </button>    
      </main>


    <FooterComponent v-if="showFooter"/>
    <ErrorModalComponent 
      :selectedText="selectedText" 
      :show="isModalVisible" 
      @close="isModalVisible = false" 
    />
  </div>
</div>

</template>



<script>
import SideMenuComponent from './components/SideMenuComponent.vue';
// import HeaderComponent from './components/HeaderComponent.vue';
import HeaderChatbotsComponent from './components/HeaderChatbotsComponent.vue';
import ErrorModalComponent from './components/ErrorModalComponent.vue'; // Импортируйте ваш компонент
import 'quill/dist/quill.snow.css';


import FooterComponent from './components/FooterComponent.vue';


import { mapActions, mapGetters, useStore} from 'vuex';
import { computed, onMounted, nextTick } from 'vue';



export default {
  name: 'App',
  components: {
    // HeaderComponent,
    SideMenuComponent,
    HeaderChatbotsComponent,
    FooterComponent,
    ErrorModalComponent
  },
  watch: {
    $route: {
        immediate: true,
        // eslint-disable-next-line
        handler(to, from) { 
            document.title = to.meta.title || '...';
        }
    },
  },
  data() {
    return {
      videoTitle: '',
      textGenerated: '',
      showTest: true,
      showTimecodes: false,
      appMounted: false,
      showHeader: true,
      showFooter: true,
      previewMode: false,
      selectedText: '',
      isModalVisible: false,
      buttonPosition: { top: '0px', left: '0px' },
      buttonVisible: false,

    };
  },
  created() {
    const urlParams = new URLSearchParams(window.location.search);
    const hash = urlParams.get('hash');
    const preview = urlParams.get('preview'); // Добавлено получение параметра preview
    const chatbot_name = urlParams.get('chatbot_name'); // Добавлено получение параметра preview

    console.log(hash);
    console.log(preview);
    this.updateHash(hash); // Dispatch the updateHash action
    
    // Если параметр preview равен 'article', то скрыть header и footer
    if (preview) {
        this.showHeaderFooter(false);
        this.enablePreview(preview);
    }    
    if (chatbot_name){
      this.setChatbotName(chatbot_name);
    }
  },
  methods: {
    ...mapActions('hash', ['updateHash']), // Map the updateHash action
    ...mapActions('app', ['setPreview', 'setChatbotName', 'setIsMobile', 'setIsTablet', 'toggleMenuVisible', 'setMenuVisible']),
 
    handleToggleMenu() {
      this.toggleMenuVisible();

    }, 

    enablePreview(type) {
      this.setPreview(type);
    },    
    showHeaderFooter(show) {
        this.showHeader = show;
        this.showFooter = show;
    },
    handleTextSelection(event) {
      // Проверяем, что клик был сделан внутри .article-container
      if (!event.target.closest('.article-container')) {
        return; // Если клик вне контейнера, выходим из функции
      }

      // Если клик был сделан по кнопке, выходим из функции
      if (event.target.tagName === 'BUTTON' || event.target.closest('button')) {
        return;
      }

      const selection = window.getSelection();

      // Проверяем, что выделение непустое и содержит хотя бы один диапазон
      if (selection.rangeCount > 0) {
        const selectedText = selection.toString();

        if (selectedText) {
          const range = selection.getRangeAt(0);
          const rect = range.getBoundingClientRect();

          this.selectedText = selectedText;

          // Позиционируем кнопку в зависимости от выделенного текста
          this.buttonPosition = {
            top: `${rect.bottom + window.scrollY}px`, // позиция сверху
            left: `${rect.left + window.scrollX}px`, // позиция слева
          };
          this.buttonVisible = true; 
        } else {
          this.buttonVisible = false;
        }
      } else {
        this.buttonVisible = false; 
      }
    },

    updateBodyClasses() {
      const width = window.innerWidth;
      const body = document.body;
      console.log('updateBodyClasses');
      // Удаляем все предыдущие классы
      body.classList.remove('isMobile', 'isMobileTablet');

      // Добавляем класс в зависимости от ширины экрана
      if (width <= 768) {
        body.classList.add('isMobile');
        this.isMobileView = true;
        this.isTabletView = false;
      } else if (width <= 1024) {
        body.classList.add('isMobileTablet');
        body.classList.add('isMobile');
        this.isMobileView = true;
        this.isTabletView = true;
      } else {
        this.isMobileView = false;
        this.isTabletView = false;
      }


      // console.log('setIsMobile');
      this.setIsMobile(this.isMobileView); 
      this.setIsTablet(this.isTabletView); 
    }    
    
  },
  computed: {
    ...mapGetters('app', ['isMobile', 'isPreview', 'chatbotName', 'configData', 'menuVisible']), 
    ...mapGetters('hash', ['getHash']), // Map the getHash getter
    ...mapGetters("article", ["getArticleItem"]),
    articleItem: {
      get() {
        return this.getArticleItem || {};
      },
      set(value) {
        this.updateArticleItem(value);
      },
    },
  },

  beforeUnmount() {
    document.removeEventListener('mouseup', this.handleTextSelection);  
    window.removeEventListener('resize', this.updateBodyClasses);

  },

  mounted() {
    this.appMounted = true;

    nextTick(() => {
      if (this.isMobile){
        this.setMenuVisible(false); 
      } else{
        this.setMenuVisible(true); 
      }
    });
    // Добавляем классы при загрузке компонента
    this.updateBodyClasses();



    window.addEventListener('resize', this.updateBodyClasses);

    document.addEventListener('mouseup', this.handleTextSelection);
    // Вставка скрипта Telegram Web Apps при монтировании
    const script = document.createElement('script');
    script.src = 'https://telegram.org/js/telegram-web-app.js';
    script.async = true;

    script.onload = () => {
      // Ваш код после полной загрузки скрипта
      var WebApp = window.Telegram.WebApp;

      console.log(WebApp);
      if (typeof WebApp.initDataUnsafe.user !== 'undefined'){
        // авторизация пользователя
        
        var user = WebApp.initDataUnsafe.user;
        user.hash = WebApp.initDataUnsafe.hash;
        user.query_id = WebApp.initDataUnsafe.query_id;
        user.auth_date = WebApp.initDataUnsafe.auth_date;
        this.store.dispatch('user/loginUser', user); 
        console.log(WebApp.initDataUnsafe.user);
      }
      


      // // Обработка данных о пользователе при входе через Telegram
      // document.addEventListener('tg-external-auth', (event) => {
      //   const { user } = event.detail;
      //   // Обработайте данные о пользователе, например, отправьте на сервер
      //   console.log('Получены данные о пользователе:', user);
      // });
    };
    document.head.appendChild(script);




  },
  setup() {
    const store = useStore();

    onMounted(async () => {
      console.log('fetch config data');
      // Вызовите новое действие для загрузки конфигурационных данных
      await store.dispatch('app/fetchConfigData');

      const configData = store.state.app.configData;
      document.title = configData.site_caption;
      const body = document.body;
      body.classList.remove('show_sidebar');
      if (configData.show_sidebar){
         body.classList.add('show_sidebar');
      }

    });

    const article = computed(() => store.getters['article/getArticleItem']);

    return {
      article,
      store
    };
  }
};
</script>

<style>
    body {
/*      font-family: 'Roboto', sans-serif;*/
/*      background: url(assets/bg-stars.svg) repeat-y;
      background-position: center 50%;
*/    
    }

    .header {
      border-top-right-radius: 0;
      border-top-left-radius: 0;
    }

    .inner {
/*      max-width: 800px;*/
    }

    #app {
      min-height: 100vh;
    }
    .tg-link {
      flex-grow: 1;
    }

    .menu a {
      margin-left: 10px;
    }

    textarea {
      border: 1px solid #BFBFBF;
      border-radius: 10px;
    }

    .time {
      font-weight: 600;
    }

    .relative{
      position: relative;
    }

    blockquote {
      background: #f1ecff;
      /* border-radius: 8px; */
      margin-bottom: 10px;
      padding: 20px;
      border-left: 4px solid #cabeff;
    }

    .absolute-center-x {
      -webkit-transform: translateX(-50%);
      -ms-transform: translateX(-50%);
      transform: translateX(-50%);
      left: 50%;
    }
    .text-success {
      color: #339933;
    }

    .fragment-imgs {
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
      grid-gap: 10px;
      margin: 20px 0;
    }

    .fragment-imgs img {
      width: 100%;
      height: auto;
      border-radius: 8px;
      margin-right: 5px;
    }

    h3 {
      color: #444;
    }

    html[data-bs-theme="dark"] blockquote {
      background-color: #344556;
    }

    html[data-bs-theme="dark"] h1, html[data-bs-theme="dark"] h2, html[data-bs-theme="dark"] h3, html[data-bs-theme="dark"] h4 {
      color: #FFF !important;
    }

    html[data-bs-theme="dark"] .shadow, html[data-bs-theme="dark"] .shadow-sm {
      box-shadow: none !important;
    }

    blockquote p {
      margin: 0;
    }

    .modal-xl{
      max-width: 90%;
    }

    .preview{
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
    }

    .preview body{
      min-width: 100%;
    }

    .xs{
      font-size: 0.5em;
    }
  
    .btn-outline-light-2{
      opacity:0.4
    }    

    .nowrap{
      text-wrap: nowrap;
    }
    
    .inline-block{
      display: inline-block;
    }

    .micro{
      font-size: 0.6em;
    }

    .menu-open .content-layout{ 
      margin-left: 220px;
    }

    .isMobile.menu-open .content-layout{ 
      margin-left: 0;
    }    

  .input-group > :not(:first-child):not(.dropdown-menu):not(.valid-tooltip):not(.valid-feedback):not(.invalid-tooltip):not(.invalid-feedback){
    background-color: var(--bs-body-bg);
  }
.word-break{
  word-break: break-word;
}
  
</style>
