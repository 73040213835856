import axiosConfig from '@/lib/axiosConfig';
import { API_URL } from '@/config.js';
import store from '@/store'; // Import the store instance

const collectionModule = {
  namespaced: true,
  state: {
    collectionItem: null,
    collections: null,
    categories: null,
    users: [],
    students: [],
  },
  mutations: {
    setCollectionItem(state, collection) {
      console.log(collection);
      state.collectionItem = collection;
    },
    setCollections(state, collections) {
      //console.log(collections);
      state.collections = collections;
    },
    updateCollectionItem(state, updatedCollection) {
      state.collectionItem = { ...state.collectionItem, ...updatedCollection };
    },
    updateMaterialsCountInCollection(state, payload) {
      const collection = state.collections.find(coll => coll.id === payload.collectionId);
      if (collection) {
        collection.materials_count = payload.materialsCount;
      }
    },
    setUsers(state, users) {
      console.log(users);
      state.users = users;
    },
    setStudents(state, students) {
      console.log(students);
      state.students = students;
    },


    
    addUser(state, user) {
      state.users.push(user);
    },

    removeUser(state, username) {
      state.users = state.users.filter(user => user.tg_username !== username);
    },

    acceptUser(state, user_status) {
      console.log(user_status);
      const index = state.users.findIndex(coll => coll.tg_user_id === user_status.tg_user_id);
      state.users[index] = user_status;
      //state.users = state.users.filter(user => user.tg_user_id !== user_status.tg_user_id);
    },
    

    updateCollectionsPriority(state, payload) {
      return axiosConfig
        .post(API_URL, {
          params:{
            action: 'update_collections_priority',
            collections: payload.collections
          }
        })
        .then(response => {
          console.log(response);
          //const collection = response.data.collection;
          //commit('setCollections', collection);          
          return response.data;
        })
        .catch(error => {
          console.error(error);
          throw error;
        });            
      // const collectionIndex = state.collections.findIndex(collection => collection.id === id);
      
      // // Обновляем поле priority
      // if (collectionIndex !== -1) {
      //   state.collections[collectionIndex].priority = priority;
      //   console.log(state.collections);
      // }

    },
    setCollectionCoverImage(state, { collectionId, coverImageUrl }) {
      const collectionIndex = state.collections.findIndex(collection => collection.id === collectionId);
      if (collectionIndex !== -1) {
        state.collections[collectionIndex].coverImageUrl = coverImageUrl;
      }
    },    
  },
  actions: {
    fetchCollection({ commit }, id) {
      return axiosConfig
        .get(API_URL, {
          params: {
            action: 'get_collection',
            id: id
          },
        })
        .then(response => {
          const collection = response.data.collection;
          commit('setCollectionItem', collection);

          if (collection.channel_id){
            store.dispatch('channel/fetchChannel', {channelId: collection.channel_id});
          }
          return response.data;
        })
        .catch(error => {
          console.error(error);
          throw error;
        });
    },

    fetchCollectionTgGroup({ state, commit }, id) {
      return axiosConfig
        .get(API_URL, {
          params: {
            action: 'get_collection_tg_group',
            id: id
          },
        })
        .then(response => {
          const collection_tg_group = response.data.collection_tg_group;

          var collection = state.collectionItem;
          collection.tg_group_id = collection_tg_group.tg_group_id;
          collection.tg_group_caption = collection_tg_group.tg_group_caption;
          commit('setCollectionItem', collection);    
          return response.data;
        })
        .catch(error => {
          console.error(error);
          throw error;
        });
    },

    deleteCollectionTgGroup({ state, commit }, id) {
      return axiosConfig
        .post(API_URL, {
          params: {
            action: 'delete_collection_tg_group',
            id: id
          },
        })
        .then(response => {
          //const collection_tg_group = response.data.collection_tg_group;
          var collection = state.collectionItem;
          collection.tg_group_id = 0;
          collection.tg_group_caption = '';
          commit('setCollectionItem', collection);          

          return response.data;
        })
        .catch(error => {
          console.error(error);
          throw error;
        });
    },


    fetchCategories({ dispatch }, channelId ) {
      //commit('reorderCollectionMaterials', payload);
      //console.log(channelId);
      dispatch('fetchCollections', { typeTab: 'categories', channelId: channelId }); 
    }, 

    fetchCollections({ commit }, payload ) {
      //console.log(payload);
      return axiosConfig
        .get(API_URL, {
          params: {
            action: 'get_collections',
            channelId: payload.channelId,
            catgoryId: payload.categoryId,
            typeTab: payload.typeTab,
          },
        })
        .then(response => {
          const collections = response.data.collections;
          //console.log(collections);
          commit('setCollections', collections);
          return response.data;
        })
        .catch(error => {
          console.error(error);
          throw error;
        });
    },
    saveCollection({ commit }, collectionItem) {
      return axiosConfig
        .post(API_URL, {
          params:{
            action: 'save_collection',
            collection: collectionItem
          }
        })
        .then(response => {
          console.log(response);

          const collection = response.data.collection;
          commit('setCollectionItem', collection);          
          return response.data;
        })
        .catch(error => {
          console.error(error);
          throw error;
        });
    },

    // eslint-disable-next-line
    setActiveCollection({ commit }, payload) {
      return axiosConfig
        .post(API_URL, {
          params:{
            action: 'set_active_collection',
            collectionId: payload.collectionId,
            tab: payload.active_tab
          }
        })
        .then(response => {
          //console.log(response);
          return response.data;
        })
        .catch(error => {
          console.error(error);
          throw error;
        });
    },    
    deleteCollection({ state, commit }) {
      const { collectionItem } = state;
      if (!collectionItem || !collectionItem.id) {
        throw new Error('Invalid collection item');
      }

      console.log(state);
      return axiosConfig
        .post(API_URL, {
          params:{
            action: 'delete_collection',
            collectionId: collectionItem.id
          }
        })
        .then(response => {
          console.log(response);
          commit('setCollectionItem', null); // Обновление collectionItem в хранилище после удаления
          return response.data;
        })
        .catch(error => {
          console.error(error);
          throw error;
        });
    },

     saveCollectionCoverImage({ commit }, { collectionId, coverImageForm }) {
      coverImageForm.append('action', 'save_collection_cover_image');
      coverImageForm.append('collectionId', collectionId);
          
      return axiosConfig.post(API_URL, coverImageForm, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
        .then(response => {
          // Если обложка подборки успешно сохранена на сервере, обновляем данные о коллекции в хранилище
          const collection = response.data.collection;
          commit('setCollectionItem', collection);
          return response.data;
        })
        .catch(error => {
          console.error('Error uploading cover image:', error);
          throw error;
        });
    },
fetchUsers({ commit }, payload ) {
  console.log(payload);
  return axiosConfig
    .get(API_URL, {
      params: {
        action: 'get_collection_users',
        collectionId: payload.collectionId,
        usersType: payload.usersType
      },
    })
    .then(response => {
      const users = response.data.collection_users;

      commit('setUsers', users);
      return response.data;
    })
    .catch(error => {
      console.error(error);
      throw error;
    });
},

fetchStudents({ commit }, payload ) {
  console.log(payload);
  return axiosConfig
    .get(API_URL, {
      params: {
        action: 'get_course_students',
        collectionId: payload.collectionId
      },
    })
    .then(response => {
      const students = response.data.course_students;

      commit('setStudents', students);
      return response.data;
    })
    .catch(error => {
      console.error(error);
      throw error;
    });
},

// eslint-disable-next-line
applyUser({ commit }, payload) {
  return axiosConfig
    .post(API_URL, {
      params: {
        action: 'apply_collection_user',
        collectionId: payload.collectionId,
        user: payload.user
      }
    })
    .then(response => {
      //commit('addUser', response.data.user);
      return response.data;
    })
    .catch(error => {
      console.error(error);
      throw error;
    });
},

acceptUser({ commit }, payload) {
  return axiosConfig
    .post(API_URL, {
      params: {
        action: 'accept_collection_user',
        collectionId: payload.collectionId,
        user: payload.user
      }
    })
    .then(response => {
      //console.log(response);
      commit('acceptUser', response.data.user_status);
      return response.data;
    })
    .catch(error => {
      console.error(error);
      throw error;
    });
},

detachUser({ commit }, payload) {
  return axiosConfig
    .post(API_URL, {
      params: {
        action: 'detach_collection_user',
        collectionId: payload.collectionId,
        user: payload.user
      }
    })
    .then(response => {
      //console.log(response);
      commit('acceptUser', response.data.user_status);
      return response.data;
    })
    .catch(error => {
      console.error(error);
      throw error;
    });
},

// eslint-disable-next-line
cancelApplyUser({ commit }, payload) {
  return axiosConfig
    .post(API_URL, {
      params: {
        action: 'cancel_apply_collection_user',
        collectionId: payload.collectionId,
        user: payload.user
      }
    })
    .then(response => {
      //commit('addUser', response.data.user);
      return response.data;
    })
    .catch(error => {
      console.error(error);
      throw error;
    });
},

addUser({ commit }, payload) {
  return axiosConfig
    .post(API_URL, {
      params: {
        action: 'add_collection_user',
        collectionId: payload.collectionId,
        user: payload.user
      }
    })
    .then(response => {
      commit('addUser', response.data.user);
      return response.data;
    })
    .catch(error => {
      console.error(error);
      throw error;
    });
},

removeUser({ commit }, payload) {
  return axiosConfig
    .post(API_URL, {
      params: {
        action: 'remove_collection_user',
        user: payload.user,
        collectionId: payload.collectionId,
      }
    })
    .then(response => {
      commit('removeUser', payload.user.username);
      return response.data;
    })
    .catch(error => {
      console.error(error);
      throw error;
    });
},

  },

  getters: {
    getCollectionItem(state) {
      return state.collectionItem;
    },
    getCollections(state) {
      return state.collections;
    },
    getCategories(state) {
      return state.categories;
    },    
    getMaterialsCountForCollection: (state) => (collectionId) => {
      const collection = state.collections.find(coll => coll.id === collectionId);
      return collection ? collection.materials_count : 0;
    },
    getUsers(state) {
      return state.users;
    },
    getStudents(state) {
      return state.students;
    },
  },
};

export default collectionModule;
