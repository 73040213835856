<template>
  <div class="input-group searchbar position-relative">
    <!-- Кнопка переключения поиска (показывается только если есть channelId или articleId) -->
    <button 
      v-if="(channelId || articleId) && searchModes.length > 1"
      class="btn btn-toggle-search-type" 
      @click="toggleSearchType"
    >
      {{ searchLabel }}
    </button>


    <!-- Поле ввода для поиска -->
    <input
      type="text"
      class="form-control search-input"
      v-model="localSearchQuery" 
      @keyup.enter="performSearch"
      :placeholder="placeholderText"
      aria-label="Поисковый запрос"
      aria-describedby="button-search"
      :style="(channelId || articleId) && searchModes.length > 1 ? 'padding-left: 110px;' : ''"
    />

    <!-- Кнопка поиска -->
    <button 
      class="btn btn-outline-secondary search-button" 
      type="button" 
      id="button-search" 
      @click="performSearch"
    >
      🔍
    </button>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  props: {
    searchType: {
      type: String,
      default: 'global'
    },
    channelId: {
      type: String,
      default: ''
    },
    articleId: {
      type: String,
      default: ''
    },
    additionalParams: {
      type: Object,
      default: () => ({})
    },
    searchQuery: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      localSearchQuery: this.searchQuery, // Локальная переменная для поиска
      searchModes: [], // Доступные режимы поиска (установим в created)
      currentModeIndex: 0 // Индекс текущего режима
    };
  },
  computed: {
    ...mapGetters('app', ['isMobile', 'configData', 'chatbotName', 'menuVisible']), 
    // Определяем текущий режим на основе индекса
    searchMode() {
      return this.searchModes[this.currentModeIndex];
    },
    placeholderText() {
      switch (this.searchMode) {
        case 'article':
          return 'Поиск по статье...';
        case 'channel':
          return 'Поиск по каналу...';
        default:
          return 'Поиск по всему сайту...';
      }
    },
    searchLabel() {
      switch (this.searchMode) {
        case 'article':
          return 'По статье';
        case 'channel':
          return 'По каналу';
        default:
          return 'По сайту';
      }
    }
  },
  methods: {
    toggleSearchType() {
      // Переключаем между всеми доступными режимами
      this.currentModeIndex = (this.currentModeIndex + 1) % this.searchModes.length;
    },
    performSearch() {
      if (this.localSearchQuery.trim()) {
        // Параметр для контекста поиска
        let contextId;
        if (this.searchMode === 'channel') {
          contextId = this.channelId;
        } else if (this.searchMode === 'article') {
          contextId = this.articleId;
        }

        // Выполняем переход на страницу поиска
        this.$router.push({
          name: 'Search',
          params: {
            query: this.localSearchQuery,
            type: this.searchMode,
            contextId
          }
        });

        // Очищаем строку поиска после выполнения
        this.localSearchQuery = '';
      }
    }
  },
  watch: {
    // Синхронизация searchQuery из props с локальной переменной
    searchQuery(newVal) {
      this.localSearchQuery = newVal;
    }
  },
  created() {
    // Проверяем, задан ли configData.channel_id для установки режима по умолчанию
    if (this.configData.channel_id) {
      if (this.articleId) {
        this.searchModes = ['article', 'channel'];
        this.currentModeIndex = 0; // Устанавливаем поиск по каналу по умолчанию
      } else {
        this.searchModes = ['channel'];
        this.currentModeIndex = 0; // Устанавливаем поиск по каналу по умолчанию
      }
    } else if (this.channelId && this.articleId) {
      this.searchModes = ['article', 'channel', 'global'];
      this.currentModeIndex = 0; // По умолчанию стартуем с поиска по статье
    } else if (this.channelId) {
      this.searchModes = ['channel', 'global'];
      this.currentModeIndex = 0; // По умолчанию стартуем с поиска по каналу
    } else if (this.articleId) {
      this.searchModes = ['article', 'global'];
      this.currentModeIndex = 0; // По умолчанию стартуем с поиска по статье
    } else {
      this.searchModes = ['global']; // Только поиск по сайту
    }
  },
};
</script>

<style scoped>
.input-group {
  display: flex;
  align-items: center;
  position: relative;
}

.btn-toggle-search-type {
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  padding: 0 10px;
  background-color: #f0f0f0;
  border: 1px solid #ccc;
  border-right: none;
  z-index: 100;
  cursor: pointer;
}

.search-input {
  /* Устанавливаем отступ только если есть channelId или articleId */
}

.search-button {
  z-index: 2;
}
</style>
