const videoPlayerModule = {
  namespaced: true,
  state: {
    currentTime: 0,
    autoPlay: false,
    mediaLink: '',
    mediaType: '',  // youtube, video, audio
    activePlayer: null  // Активный плеер (modal, sidebar)
  },
  mutations: {
    setMediaData(state, { link, time, type, autoplay }) {
      state.mediaLink = link;
      state.currentTime = time;
      state.mediaType = type;
      state.autoPlay = autoplay;
    },
    setCurrentTime(state, time) {
      state.currentTime = time;
    },
    setActivePlayer(state, player) {
      state.activePlayer = player;
    },
    setAutoPlay(state, autoplay) {
      state.autoPlay = autoplay;
    }    
  },
  actions: {
    updateMediaData({ commit }, { link, time, type, autoplay }) {
      commit('setMediaData', { link, time, type, autoplay });
    },
    updateCurrentTime({ commit }, time) {
      commit('setCurrentTime', time);
    },
    setActivePlayer({ commit }, player) {
      commit('setActivePlayer', player);
    },
    setAutoPlay({ commit }, autoplay) {
      commit('setAutoPlay', autoplay);
    }
  },
  getters: {
    getCurrentTime(state) {
      return state.currentTime;
    },
    getAutoPlay(state) {
      return state.autoPlay;
    },
    getMediaLink(state) {
      return state.mediaLink;
    },
    getMediaType(state) {
      return state.mediaType;
    },
    getActivePlayer(state) {
      return state.activePlayer;
    }
  }
};

export default videoPlayerModule;
