<template>
<div>
  <div class=" mb-3 text-center" v-if="categories.length <= 8">
    <!-- Если количество коллекций <= 8, отображаем все кнопки коллекций -->
    <button
      v-if="selectedCategoryId !== -1"
      class="btn btn-outline-primary me-2 mb-2"
      :class="{ active: selectedCategoryId === -1 }"
      @click="selectCategory({ id: -1 })"
    >
      Показать все материалы
    </button>
    <button
      v-for="category in categories"
      :key="category.id"
      class="btn btn-outline-primary me-2 mb-2"
      :class="{ active: selectedCategoryId === category.id }"
      @click="selectCategory(category)"
    >
      {{ category.caption }} <span v-if="category.materials_count">({{ category.materials_count }})</span>
    </button>
  </div>

  <div class="d-flex justify-content-center mb-3" v-else>
    <!-- Кнопка для выбора плейлиста -->
    <button class="btn btn-primary me-2" @click="showModal = true">
      {{ activeCategoryName }}
    </button>
    <!-- Новая кнопка "Показать все", отображаемая только при выбранной категории -->
    <button
      v-if="selectedCategoryId !== -1"
      class="btn btn-outline-secondary"
      @click="selectCategory({ id: -1 })"
    >
      Показать все
    </button>
  </div>

  <!-- Модальное окно для выбора плейлиста -->
  <div v-if="showModal" class="modal fade show" style="display: block;">
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Выбрать плейлист</h5>
          <button type="button" class="btn-close" @click="closeModal"></button>
        </div>
        <div class="modal-body">
          <ul class="list-group">
            <li class="list-group-item d-flex justify-content-between align-items-center" @click="selectCategoryAndClose({ id: -1 })">
              <span>Показать все</span>
              <button class="btn btn-primary btn-sm">Выбрать</button>
            </li>
            <li v-for="category in categories" :key="category.id" class="list-group-item d-flex justify-content-between align-items-center">
              <span>{{ category.caption }} <span v-if="category.materials_count">({{ category.materials_count }})</span></span>
              <button class="btn btn-primary btn-sm" @click="selectCategoryAndClose(category)">Выбрать</button>
            </li>
          </ul>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" @click="closeModal">Закрыть</button>
        </div>
      </div>
    </div>
  </div>
  <overlay-component :visible="showModal"></overlay-component>
</div>
</template>

<script>
import OverlayComponent from '@/components/OverlayComponent.vue';

export default {
  props: {
    categories: {
      type: Array,
      required: true
    },
    selectedCategoryId: {
      type: Number,
      required: true
    },
    hasEditAccess: {
      type: Boolean,
      required: true
    }
  },
  data() {
    return {
      showModal: false
    };
  },
  computed: {
    activeCategoryName() {
      if (this.selectedCategoryId === -1) {
        return 'Выбрать плейлист';  
      }
      const activeCategory = this.categories.find(category => category.id === this.selectedCategoryId);
      return activeCategory ? `${activeCategory.caption} (${activeCategory.materials_count || 0})` : 'Выбрать плейлист';  // Возвращаем название коллекции
    }
  }, 

  methods: {
    selectCategory(category) {
      this.$emit('select-category', category);
    },
    selectCategoryAndClose(category) {
      this.selectCategory(category);
      this.closeModal();
    },
    closeModal() {
      this.showModal = false;
    }
  },
  components: {
    OverlayComponent
  }
};
</script>

<style scoped>
.category-btn {
  display: flex;
  align-items: center;
}

.modal .list-group-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.modal-title {
  margin: 0;
}
</style>
