import axios from 'axios';
import { API_URL } from '@/config.js';
import store from '@/store'; // Import the store instance

const axiosInstance = axios.create({
  baseURL: API_URL,
  // Other default configurations
});

axiosInstance.interceptors.request.use(
  (config) => {
    const urlParams = new URLSearchParams(window.location.search);
  
    let chatbot_name = urlParams.get('chatbot_name');
    if (!chatbot_name) chatbot_name = '';


    const authToken = localStorage.getItem('authToken');
    config.headers['Authorization'] = `Bearer ${authToken}`;
    //const urlParams = new URLSearchParams(window.location.search);
    const hash = store.getters['hash/getHash']; // Access the hash value from the hashModule using store getters
    //console.log(hash);
    if (hash) {
      config.params = {
        ...config.params,
        hash: hash,
      };
    }
    if (chatbot_name) {
      config.params = {
        ...config.params,
        chatbot_name: chatbot_name,
      };
    }

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response && error.response.status === 401) {
      // Handle unauthorized access
      store.commit('user/setAuthentication', false);
    }
    return Promise.reject(error);
  }
);

export default axiosInstance;
