import axiosConfig from '@/lib/axiosConfig';
import { API_URL } from '@/config.js';

const narezkaModule = {
  namespaced: true,
  state: {
    video: null,
    videos: []
  },
  mutations: {
    setVideo(state, video) {
      state.video = video;
    },
    setVideos(state, videos) {
      state.videos = videos;
    },
    updateVideo(state, updatedVideo) {
      const index = state.videos.findIndex(video => video.id === updatedVideo.id);
      if (index !== -1) {
        state.videos.splice(index, 1, updatedVideo);
      } else {
        state.videos.push(updatedVideo);
      }
    }
  },
  actions: {
    async generateFinalVideo({ dispatch }, video) {
      try {
        const response = await axiosConfig.post(`${API_URL}`, {
          params: {
            action: 'narezka_generate_final_video',
            videoId: video.id
          }
        });

        if (response.data.result == 'success') {
          dispatch('pollVideoStatus', { videoIndex: video.id, videoId: video.id });
        } else {
          console.error('Error generating final video:', response.data.message);
        }
      } catch (error) {
        console.error('Error generating final video:', error);
        throw error;
      }
    },
    async generateVideo({ dispatch }, { materialId, videoIndex, video }) {
      try {
        const response = await axiosConfig.post(`${API_URL}`, {
          params: {
            action: 'generate_new_video',
            materialId: materialId,
            videoId: video.id,
            video: video
          }
        });

        if (response.data.result === 'success') {
          dispatch('pollVideoStatus', { videoIndex, videoId: response.data.video.id });
        } else {
          console.error('Error generating video:', response.data.message);
        }
      } catch (error) {
        console.error('Error generating video:', error);
        throw error;
      }
    },
    async pollVideoStatus({ commit, state }, { videoIndex, videoId }) {
      const pollInterval = setInterval(async () => {
        try {
          const response = await axiosConfig.get(`${API_URL}`, {
            params: {
              action: 'get_video_status',
              videoId: videoId
            }
          });

          if (response.data.video.status !== 'generate') {
            clearInterval(pollInterval);
            const updatedVideos = [...state.videos];
            updatedVideos[videoIndex] = response.data.video;
            commit('setVideos', updatedVideos);
          }
        } catch (error) {
          clearInterval(pollInterval);
          console.error('Error polling video status:', error);
        }
      }, 10000); // Poll every 10 seconds
    }
  },
  getters: {
    getVideo: state => state.video,
    getVideos: state => state.videos
  }
};

export default narezkaModule;
