import axiosConfig from '@/lib/axiosConfig';
import { API_URL } from '@/config.js';

const historyModule = {
  namespaced: true,
  state: {
    viewHistory: [], // История просмотров
    pagination: {
      page: 1,
      limit: 10,
      total: 0
    }
  },
  mutations: {
    setViewHistory(state, { data, pagination }) {
      state.viewHistory = data;
      state.pagination = pagination;
    },
    appendToViewHistory(state, { data }) {
      data.forEach(newGroup => {
        const existingGroup = state.viewHistory.find(group => group.display_date === newGroup.display_date);
        if (existingGroup) {
          // Если группа с такой датой уже существует, добавляем материалы к ней
          existingGroup.items = [...existingGroup.items, ...newGroup.items];
        } else {
          // Если группы с такой датой нет, добавляем новую
          state.viewHistory.push(newGroup);
        }
      });
    },
    setPagination(state, pagination) {
      state.pagination = pagination;
    }
  },
  actions: {
    // Метод для получения истории с поддержкой пагинации
    // eslint-disable-next-line    
    fetchViewHistory({ commit }, { page = 1, limit = 10 }) {
      return axiosConfig
        .get(API_URL, {
          params: {
            action: 'get_view_history',  // Указываем действие для получения истории
            page: page,
            limit: limit
          },
        })
        .then(response => {
          const { data, pagination } = response.data;
          // Если это первая страница, заменяем историю
          if (page === 1) {
            commit('setViewHistory', { data, pagination });
          } else {
            // Иначе добавляем новые данные к существующим
            commit('appendToViewHistory', { data });
            commit('setPagination', pagination); // Обновляем информацию о пагинации
          }
        })
        .catch(error => {
          console.error('Error fetching view history:', error);
          throw error;
        });
    }
  },
  getters: {
    getViewHistory(state) {
      return state.viewHistory;
    },
    getPagination(state) {
      return state.pagination;
    }
  }
};

export default historyModule;
