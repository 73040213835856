<template>
  <div class="header shadow-sm rounded py-3 ps-0 pe-2 mt-2">
    <a name="header"></a>
    <div class="d-flex justify-content-between align-items-center me-2">
      <div class="" v-if="collectionItem.id && collectionItem.tariff">
        <div @click="redirectToExternalUrl(configData.link)" class="text-decoration-none" v-if="collectionItem.id && collectionItem.tariff && collectionItem.tariff.tariff == 'Free' && configData.logo_thumbs">
        <div class="tg-link d-flex align-items-center" >
          <img :src="configData.black_logo_thumbs.middle" width="250" class="" alt="" v-if="configData.black_logo_thumbs"/>
          
          <img :src="configData.logo_thumbs.middle" width="250" class="" alt="" v-if="!configData.black_logo_thumbs"/>
        </div>
        </div>

        <div @click="redirectToExternalUrl(channelItem.link)" class="text-decoration-none" v-if="channelItem.logo_thumbs &&  collectionItem.tariff.tariff !== 'Free'">
        <div class="tg-link d-flex align-items-center" >
          
          <img :src="channelItem.logo_thumbs.middle" class="header-logo" width="250" alt="" />
          
        </div>
        </div>
      </div>

      <div class="" v-if="!collectionItem.id">
        <!-- <a :href="configData.link" class="text-decoration-none" v-if="configData.logo_thumbs && (!channelItem.logo_thumbs || $route.name == 'Collections')"> -->
        <a :href="configData.link" class="text-decoration-none" v-if="configData.logo_thumbs">
        <div class="tg-link d-flex align-items-center" >
          
          <img :src="configData.black_logo_thumbs.middle" v-if="configData.black_logo_thumbs" width="300" class="" alt="" />
          <img :src="configData.logo_thumbs.middle" v-if="!configData.black_logo_thumbs" width="300" class="" alt="" />
        </div>
        </a>
      </div>
      <telegram-auth v-show="!isPreview" v-if="route && route.name !== 'Main' && route.name !== 'Collections' && (!collectionItem.id || isAuthenticated) && !configData.disable_telegram_auth"></telegram-auth>

        
    </div>
    
  </div>
</template> 

<script>


import { computed } from 'vue';
import { useStore } from 'vuex';
import { mapGetters, mapActions } from 'vuex';
import { useRoute } from 'vue-router';
import TelegramAuth from './TelegramAuth.vue';

export default {
  components: {
    TelegramAuth
  },
  data() {
    return {
      showSupportModal: false,
      showMenu: false
    };
  },
  methods: {
    ...mapActions('app', ['setIsMobile']),

    redirectToExternalUrl(url) {
      if (!this.isPreview) window.location.href = url;
    },
    openMenu() {
      this.showMenu = true;
    },
    closeMenu() {
      this.showMenu = false;
    }
  },
  computed: {
    ...mapGetters('app', ['isMobile', 'configData', 'isPreview', 'chatbotName']), 
    ...mapGetters("article", ["getArticleItem"]),
    ...mapGetters("channel", ["getChannelItem"]),
    ...mapGetters("collection", ["getCollectionItem"]),

    isMobile() {
      return this.$store.getters['app/isMobile'];
    },
    configData() {
      return this.$store.getters['app/configData'];
    },

    articleItem: {
      get() {
        return this.getArticleItem || {};
      },
      set(value) {
        this.updateArticleItem(value);
      },
    },
    channelItem: {
      get() {
        return this.getChannelItem || {};
      },
      set(value) {
        this.updateChannelItem(value);
      },
    },
    collectionItem: {
      get() {
        return this.getCollectionItem || {};
      },
      set(value) {
        this.updateCollectionItem(value);
      },
    },          
  },
  setup() {
    const route = useRoute();
    const store = useStore();
    const article = computed(() => store.getters['article/getArticleItem']);
    const collection = computed(() => store.getters['collection/getCollectionItem']);
    const isAuthenticated = computed(() => store.getters['user/isAuthenticated']);
    //const channel = computed(() => store.getters['channel/getChannelItem']);


    return {
      article, collection, isAuthenticated, route
    };
  },
  mounted() {
    // Проверьте ширину окна при загрузке компонента
    this.setIsMobile(window.innerWidth < 768);

    // Следите за изменением ширины окна и обновляйте параметр isMobile при необходимости
    this.resizeListener = () => {
      this.setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener('resize', this.resizeListener);
  },
  beforeUnmount() {
    window.removeEventListener('resize', this.resizeListener);
  }
};
</script>

<style scoped>

.header-logo{
  border-radius: 10px
}

</style>
