<template>
  <component :is="componentToRender" :id="channelId" />
</template>

<script>
import { computed } from 'vue';
import { useStore } from 'vuex';
import MainComponent from '@/components/MainComponent.vue';
import ChannelComponent from '@/components/ChannelComponent.vue';

export default {
  setup() {

    const store = useStore();
    
    const configData = computed(() => store.getters['app/configData']);

    const componentToRender = computed(() => {
      console.log(configData.value.channel_id);
      return configData.value && configData.value.channel_id ? ChannelComponent : MainComponent;
    });
    
    const channelId = computed(() => configData.value?.channel_id || null);

    return {
      componentToRender,
      channelId
    };
  }
};
</script>
