import axiosConfig from '@/lib/axiosConfig';

import { API_URL } from '@/config.js';

const tgConnector = {
  namespaced: true,
  state: {
    
  },
  mutations: {
    
  },
  actions: {

    sendCommandToBot({ commit, rootGetters }, { command, params }) { // eslint-disable-line no-unused-vars
      // Отправка команды и параметров на бэкенд чат-бота
      // window.Telegram.WebApp.close();      
      const configData = rootGetters['app/configData'];
      
      axiosConfig
        .post(API_URL + '?action=send_bot_command', { command, params })
        .then(response => {
          console.log(response);
          //console.log('Команда успешно отправлена на бота');
          if (configData.close_web_app) {
            window.Telegram.WebApp.close();
          }      

        })
        .catch(error => {
          console.error('Ошибка отправки команды на бота:', error);
        });
    },
  },
  getters: {
    
  },
};

export default tgConnector;
