import axiosConfig from '@/lib/axiosConfig';
import { API_URL } from '@/config.js';

const userModule = {
  namespaced: true,
  state: {
    user: null,
    isAuthenticated: false,
    hasEditAccess: false
  },
  mutations: {
    setUser(state, user) {
      state.user = user;
    },
    setAuthentication(state, isAuthenticated) {
      state.isAuthenticated = isAuthenticated;
    },
    setEditAccess(state, hasEditAccess) { // Add the setEditAccess mutation
      state.hasEditAccess = hasEditAccess;
    },    
  },
  actions: {
    updateAuthentication({ commit }, isAuthenticated) {
      commit('setAuthentication', isAuthenticated);
    },
    updateUser({ commit }, user) {
      commit('setUser', user);
    },

    loginUser({ commit, dispatch }, user) {
      axiosConfig
      .post(API_URL + '?action=login', {
        params: {
          user: user
        },
      })
      .then(response => {
        console.log('login auth');
        console.log(response.data);
        if (response.data.authToken) {
          const authToken = response.data.authToken;
          localStorage.setItem('authToken', authToken); // Save the authToken in localStorage
          commit('setAuthentication', true);
          dispatch('checkUser'); // Call the checkUser action to update the user state
        }
      })
      .catch(error => {
        console.error('Login error:', error);
      });
    },


    logoutUser({ commit }) {
      localStorage.removeItem('authToken'); // Remove the authToken from localStorage
      commit('setUser', null);
      commit('setAuthentication', false);
    },

    saveUser({ state }) {
      axiosConfig
        .post(API_URL + '?action=save_user', {
          params: {
            user: state.user,
          },
        })
        .then(response => {
          console.log(response);
          console.log('User data saved successfully');
        })
        .catch(error => {
          console.error('Error saving user data:', error);
        });
    },
    fetchUserAccess({ commit }) {
      axiosConfig
        .get(API_URL, {
          params: {
            action: 'get_user_access',
          },
        })
        .then(response => {
          const hasAccess = response.data.hasAccess;
          commit('setEditAccess', hasAccess); // Commit the hasEditAccess mutation
          return response.data;
        })
        .catch(error => {
          console.error('Error fetching user access:', error);
          throw error;
        });
    },    
    checkUser({ commit, dispatch }) {
      const authToken = localStorage.getItem('authToken');
      if (authToken && authToken !== 'undefined') {
        axiosConfig
          .get(API_URL + '?action=check_user', {
            headers: {
              Authorization: `Bearer ${authToken}`,
            },
          })
          .then(response => {
            commit('setUser', response.data.user);
            commit('setAuthentication', true);
            dispatch('fetchUserAccess'); // 
          })
          .catch(error => {
            console.error('User check error:', error);
          });
      } else {
        commit('setUser', null);
        commit('setAuthentication', false);
      }
    },
  },
  getters: {
    getUser(state) {
      return state.user;
    },
    isAuthenticated(state) {
      return state.isAuthenticated;
    },
    hasEditAccess(state) {
      return state.hasEditAccess;
    },
  },
};

export default userModule;
