import axiosConfig from '@/lib/axiosConfig';
import { API_URL } from '@/config.js';

const shlokaModule = {
  namespaced: true,
  state: {
    shlokaItem: null, // Объект для хранения данных шлоки
  },
  mutations: {
    setShlokaItem(state, shloka) {
      state.shlokaItem = shloka;
    }
  },
  actions: {
    // Загрузка данных о шлоке по алиасу
    fetchShloka({ commit }, alias) {
      return axiosConfig
        .get(API_URL, {
          params: {
            action: 'get_shloka',
            alias: alias
          },
        })
        .then(response => {
          const shloka = response.data.shloka;
          commit('setShlokaItem', shloka);
        })
        .catch(error => {
          console.error('Ошибка при загрузке шлоки:', error);
          throw error;
        });
    }
  },
  getters: {
    getShlokaItem(state) {
      return state.shlokaItem;
    }
  }
};

export default shlokaModule;
