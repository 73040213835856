<template>

  <div v-if="isAuthenticated" class="telegram-auth text-center">
    <div class="auth-container align-items-center">

      <div class="d-flex">
        <img v-if="user && user.photo_url" :src="user.photo_url" :alt="user.first_name" class="rounded-circle" style="width: 40px; height: 40px; margin-right: 10px;">
        <h6 class="name w-100">{{ user && user.first_name }}</h6>
      </div>
      <button @click="logout" class="btn btn-sm btn-light nowrap logout">
        <i class="bi bi-box-arrow-right"></i> выйти
      </button>
    </div>
  </div>
  <div v-else>
  <!-- telegram-login="bystrokurs_bot" -->
    <span v-if="!isLoaded">Loading...</span>
    <telegram-login-temp
      mode="callback"
      :telegram-login="configData.bot_username"
      @loaded="telegramLoadedCallbackFunc"
      @callback="authCallbackFunction"
      v-if="configData.bot_username"
    />
  </div>

</template>

<script>
import { telegramLoginTemp } from 'vue3-telegram-login';
import { ref, computed, onMounted } from 'vue';
import { useStore } from 'vuex';
import { mapGetters } from 'vuex';

export default {
  components: {
    telegramLoginTemp
  },
  computed: {
    ...mapGetters('app', ['isMobile', 'configData']), 
  },  
  setup() {
    const isLoaded = ref(false);
    const store = useStore();

    const telegramLoadedCallbackFunc = () => {
      console.log('Script is loaded');
      isLoaded.value = true;
      authCallbackFunction();
    };

    const authCallbackFunction = (user) => {


      //user = {"id":295557514,"first_name":"Alexey","last_name":"Yarmots","username":"AYarmots","photo_url":"https://t.me/i/userpic/320/bIv5EUrBe0f_SsyMpf_8vgOE8aqg2rE-sDkz-mb5cSE.jpg","auth_date":1692852078,"hash":"2deebcdd5db59a04fa837061ac0482babc10933fd5336f7cb3ed3015d6eb46d6"};

      console.log(user);
      
      // if (typeof user == 'undefined'){
        //user = {"id":295557514,"first_name":"Alexey","last_name":"Yarmots","username":"AYarmots","photo_url":"https://t.me/i/userpic/320/bIv5EUrBe0f_SsyMpf_8vgOE8aqg2rE-sDkz-mb5cSE.jpg","auth_date":1700464855,"hash":"15716a769571eafd8ff5a8339893ae1f51764dd3fd21ba6f47d0db83556beb71"};
      // }
      
      saveUserDataLocally(user);
      store.dispatch('user/loginUser', user);      
      //saveUser
      //store.dispatch('user/checkUser'); // Используем действие checkUser для проверки данных пользователя
    };

    const saveUserDataLocally = (user) => {
      localStorage.setItem('userData', JSON.stringify(user));
    };

    const logout = () => {
      localStorage.removeItem('userData');
      localStorage.removeItem('authToken');
      store.dispatch('user/updateAuthentication', false);
      store.dispatch('user/updateUser', null);
    };

    onMounted(() => {
      //authCallbackFunction();
      console.log('check user!');
      store.dispatch('user/checkUser'); // Вызываем действие checkUser при загрузке компонента
    });

    const isAuthenticated = computed(() => store.getters['user/isAuthenticated']);
    const user = computed(() => store.getters['user/getUser']);

    return {
      isLoaded,
      isAuthenticated,
      user,
      telegramLoadedCallbackFunc,
      authCallbackFunction,
      logout
    };
  }
};
</script>
<style>
.auth-container{
  display: flex;
  width: 100%;
}
.auth-container .name{
  margin-bottom: 0;
  margin-right: 10px;
}

.side-menu .auth-container{
  display: block;
}

.side-menu .auth-container .name{
  margin: 0 0 10px 0;
}

.side-menu .auth-container .logout{
  width: 100%
}

</style>
