<template>
  <div class="modal fade show" tabindex="-1" role="dialog" v-if="show" style="display: block;">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Сообщение об ошибке</h5>
          <button type="button" class="btn-close" @click="closeModal" aria-label="Close" :disabled="isSubmitting"></button>
        </div>
        <div class="modal-body">
          <p>Выделенный текст:</p>
          <p>{{ selectedText }}</p>
          <textarea v-model="errorMessage" placeholder="Опишите ошибку..." class="form-control" :disabled="isSubmitting"></textarea>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" @click="closeModal" :disabled="isSubmitting">Закрыть</button>
          <button type="button" class="btn btn-primary" @click="sendErrorReport" :disabled="isSubmitting">
            <spinner-component v-if="isSubmitting" :visible="true" />
            <span v-else>Отправить</span>
          </button>
        </div>
      </div>
    </div>
  </div>
  <overlay-component :visible="show" class="onfront"></overlay-component>
</template>

<script>
import OverlayComponent from '@/components/OverlayComponent.vue';
import SpinnerComponent from '@/components/SpinnerComponent.vue';
import { useToast } from "vue-toastification";

export default {
  props: {
    selectedText: {
      type: String,
      required: true,
    },
    show: {
      type: Boolean,
      required: true,
    },
  },
  emits: ['close'],
  components: {
    OverlayComponent,
    SpinnerComponent,
  },  
  data() {
    return {
      errorMessage: '',
      isSubmitting: false, // Добавляем состояние для отслеживания отправки
    };
  },
  methods: {
    closeModal() {
      this.$emit('close');
    },

    sendErrorReport() {
      this.isSubmitting = true; // Устанавливаем состояние в true при начале отправки
      const currentUrl = window.location.href;
      const context = this.getContextAroundSelection(this.selectedText);

      this.$store.dispatch('app/sendErrorReport', {
        selectedText: this.selectedText,
        additionalInfo: this.errorMessage,
        page: currentUrl,
        context: context,
      })
      .then(response => {
        if (response && response.result === 'success') {
          const toast = useToast();
          this.errorMessage = '';
          toast.success("Спасибо! Сообщение об ошибке отправлено.", {
            timeout: 2000,
          });
          this.closeModal();
        }
      })
      .catch(error => {
        console.error('Error sending report:', error);
      })
      .finally(() => {
        this.isSubmitting = false; // Возвращаем состояние в false после завершения
      });
    },

    getContextAroundSelection(selectedText) {
      const pageText = document.body.innerText;
      const selectionStart = pageText.indexOf(selectedText);

      if (selectionStart === -1) {
        return '';
      }

      const contextRange = 30;
      const start = Math.max(0, selectionStart - contextRange);
      const end = Math.min(pageText.length, selectionStart + selectedText.length + contextRange);

      return pageText.slice(start, end);
    }
  },
};
</script>
