import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap';
import { createApp } from 'vue';
import App from './App.vue';
import store from './store';
import 'bootstrap-icons/font/bootstrap-icons.css';
import Toast from 'vue-toastification';
import 'vue-toastification/dist/index.css';
import axiosConfig from './lib/axiosConfig';
import VueClipboard from 'vue-clipboard3';
import VueYandexMetrika from 'vue3-yandex-metrika';
import { router } from './router'; // Импортируем роутер

const app = createApp(App);

// Используем базовый роутер
app.use(router);


// Подключаем Vuex store
app.use(store);

// Подключаем дополнительные плагины и глобальные свойства
const options = {
  // Вы можете установить ваши настройки по умолчанию здесь
};
app.use(Toast, options); // Подключаем Toast
app.use(VueClipboard); // Подключаем VueClipboard

// Инициализация глобальных свойств
app.config.globalProperties.$axios = axiosConfig;

app.config.globalProperties.$encodeFilterParameter = function(filters) {
  const filterKeys = Object.keys(filters);
  const encodedParams = filterKeys.map(key => `filters[${encodeURIComponent(key)}]=${encodeURIComponent(filters[key])}`);
  return encodedParams.join('&');
};


// Получение ID для Яндекс Метрики с сервера и инициализация
store.dispatch('app/fetchConfigData').then(() => {
  const metrikaId = store.state.app.configData.metrika_id;

  if (metrikaId) {
    app.use(VueYandexMetrika, {
      id: metrikaId,
      router, // Передаем роутер в Yandex Metrika
      env: process.env.NODE_ENV
    });
  }
}).catch(error => {
  console.error('Ошибка при получении данных конфигурации', error);
});

// Монтируем приложение
app.mount('#app');
