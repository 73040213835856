import axiosConfig from '@/lib/axiosConfig';
import { API_URL } from '@/config.js';

const searchModule = {
  namespaced: true,
  state: {
    searchResults: [],  // Список результатов поиска (list)
    generalSummary: null,  // Общее описание (general_summary)
    isLoading: false,
    error: null,
  },
  mutations: {
    setSearchResults(state, results) {
      console.log(results);
      state.searchResults = results;
    },
    setGeneralSummary(state, summary) {
      state.generalSummary = summary;
    },
    setIsLoading(state, isLoading) {
      state.isLoading = isLoading;
    },
    setError(state, error) {
      state.error = error;
    }
  },
  actions: {
    async performSearch({ commit }, { searchQuery, searchType, contextId }) {
      commit('setIsLoading', true);
      commit('setError', null);

      console.log(searchQuery, searchType, contextId); // Проверка данных

      try {
        const params = {
          action: 'search',
          query: searchQuery,
          type: searchType,
          contextId // Передача универсального ID для контекста (например, channelId, playlistId)
        };

        const response = await axiosConfig.get(API_URL, { params });
        console.log(response);
        // Обновляем список результатов и общий summary
        commit('setSearchResults', response.data.results.list);  // Список результатов
        commit('setGeneralSummary', response.data.results.general_summary);  // Общее описание
      } catch (error) {
        console.error('Error performing search:', error);
        commit('setError', error.message);
      } finally {
        commit('setIsLoading', false);
      }
    }
  },
  getters: {
    getSearchResults(state) {
      return state.searchResults;
    },
    getGeneralSummary(state) {
      return state.generalSummary;
    },
    getIsLoading(state) {
      return state.isLoading;
    },
    getError(state) {
      return state.error;
    }
  }
};

export default searchModule;
