<template>
  <div>
    <!-- Кнопка-гамбургер, которая показывается, когда меню свёрнуто -->
    <button v-if="!isVisible" @click="$emit('toggleMenu')" class="hamburger-btn">
      <i class="bi bi-list"></i>
    </button>

    <!-- Боковое меню с анимацией -->
    <transition name="slide" v-if="configData && configData.menu">
      <div class="side-menu" v-show="isVisible">
        <router-link to="/" class="menu-item mb-0" v-if="configData.logo_thumbs">
          <img :src="configData.logo_thumbs.middle" class="w-100" alt="" />
        </router-link>

        <div class="btn-collapse-menu-container">
          <button @click="$emit('toggleMenu')" class="btn btn-light collapse-menu-btn">
            <i class="bi bi-chevron-left"></i> Скрыть меню

            <!-- <i class="bi bi-list"></i> -->
          </button>
        </div>

        <router-link to="/" class="menu-item" v-if="configData.menu.main">
          <i class="bi bi-house-door"></i>
          <span>Главная</span>
        </router-link>

          <router-link to="/history" class="menu-item" v-if="configData.menu.history">
            <i class="bi bi-clock-history"></i>
            <span>{{ configData.menu.history.caption }}</span>
          </router-link>

        <div v-if="!configData.block_data_management">
          <router-link to="/channels" class="menu-item" v-if="configData.menu.channels">
            <i class="bi bi-collection"></i>
            <span>{{ configData.menu.channels.caption }}</span>
          </router-link>

          <router-link to="/collections" class="menu-item" v-if="configData.menu.collections">
            <i class="bi bi-boxes"></i>
            <span>{{ configData.menu.collections.caption }}</span>
          </router-link>

          <router-link to="/materials" class="menu-item" v-if="configData.menu.materials">
            <i class="bi bi-journal"></i>
            <span>{{ configData.menu.materials.caption }}</span>
          </router-link>
        </div>

        <telegram-auth class="mb-3" v-if="!configData.disable_telegram_auth"></telegram-auth>
      </div>
    </transition>

    <overlay-component :visible="isVisible && isMobile" @click="$emit('toggleMenu')"></overlay-component>
  </div>
</template>


<script>
import { computed, nextTick } from 'vue';
import { useStore } from 'vuex';
import { mapGetters, mapActions } from 'vuex';
import OverlayComponent from '@/components/OverlayComponent.vue';
import useClipboard from 'vue-clipboard3'
import { useToast } from "vue-toastification";
import TelegramAuth from './TelegramAuth.vue';
import { useRoute } from 'vue-router';

export default {
  props: {
    isVisible: {
      type: Boolean,
      required: true,
    }
  },

  data(){
    return{
      menuExpanded: this.isVisible, // Управляет состоянием меню
    }
  },

  components: {
    OverlayComponent,
    TelegramAuth
  },  

  watch: {
    // Следим за изменениями параметра isVisible
    isVisible(newVal) {
      this.menuExpanded = newVal;
    }
  },  
  methods: {
    ...mapActions('app', ['setIsMobile']),

    

  },
  mounted() {
    // Устанавливаем класс при монтировании компонента

    nextTick(() => {
      console.log('isMobile ', this.isMobile);
      if (this.isMobile){
        this.menuExpanded = false;
        console.log(this.menuExpanded);
      } else {
        this.menuExpanded = true;
      }


    });
  },
  computed: {
    ...mapGetters('app', ['isMobile', 'configData']), 
    ...mapGetters("article", ["getArticleItem"]),
    ...mapGetters("channel", ["getChannelItem"]),
    ...mapGetters("collection", ["getCollectionItem"]),

    articleItem: {
      get() {
        return this.getArticleItem || {};
      },
      set(value) {
        this.updateArticleItem(value);
      },
    },
    channelItem: {
      get() {
        return this.getChannelItem || {};
      },
      set(value) {
        this.updateChannelItem(value);
      },
    },
    collectionItem: {
      get() {
        return this.getCollectionItem || {};
      },
      set(value) {
        this.updateCollectionItem(value);
      },
    }
  },
  setup() {
    const route = useRoute();
    const store = useStore();
    const article = computed(() => store.getters['article/getArticleItem']);
    const collection = computed(() => store.getters['collection/getCollectionItem']);
    const isAuthenticated = computed(() => store.getters['user/isAuthenticated']);

    const { toClipboard } = useClipboard()

    const copy = async (text) => {
      try {
        await toClipboard(text)
        const toast = useToast();
        toast.success("Скопировано в буфер обмена", { timeout: 2000 });
      } catch (e) {
        console.error(e)
      }
    }

    return {
      article, collection, copy, isAuthenticated, route
    };
  } 
};
</script>

<style scoped>
/* Боковое меню */
.side-menu {
  position: fixed;
  left: 0;
  top: 0;
  height: 100%;
  width: 250px;
  background-color: #f8f9fa;
  padding: 20px;
  box-shadow: 2px 0 5px rgba(0, 0, 0, 0.1);
  z-index: 99;
  transition: transform 0.3s ease;
}

html[data-bs-theme="dark"] .side-menu {
  background-color:  var(--bs-body-bg);
}

.isMobile .side-menu {
  z-index: 101;  
}
/* Стиль для пунктов меню */
.side-menu .menu-item {
  display: flex;
  align-items: center;
  padding: 10px 15px;
  margin-bottom: 10px;
  color: #333;
  font-weight: 500;
  text-decoration: none;
  background-color: #fff;
  border-radius: 8px;
  transition: background-color 0.3s, color 0.3s;
}

html[data-bs-theme="dark"] .side-menu .menu-item{
  background-color: initial;
  color: #FFF;
}

/* Иконки рядом с текстом в меню */
.side-menu .menu-item i {
  font-size: 20px;
  margin-right: 10px;
  color: #6c757d; /* Цвет иконок */
}

/* Текст в меню */
.side-menu .menu-item span {
  font-size: 16px;
}

/* Эффект при наведении */
.side-menu .menu-item:hover {
  background-color: #e7f3ff; /* Светло-голубой цвет при наведении */
  color: #007bff; /* Цвет текста при наведении */
}

/* Активный элемент меню */
.side-menu .menu-item-active {
  background-color: #e0f0ff;
  color: #007bff;
}

/* Кнопка для открытия меню (гамбургер) */
.hamburger-btn {
  position: fixed;
  top: 0.7rem;
  left: 10px;
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;
  z-index: 22;
}

.btn-collapse-menu-container{
  position: relative;
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  height: 30px;
}

.collapse-menu-btn {
  background: none;
  border: none;
  font-size: 1rem;
  color: #999;
}
/* Анимация для бокового меню */
.slide-enter-active, .slide-leave-active {
  transition: transform 0.3s ease;
}

.slide-enter, .slide-leave-to {
  transform: translateX(-100%);
}

.side-menu .telegram-auth{
  display: flex;
  align-items: center;
  padding: 10px 15px;
  justify-content: center;
  margin-bottom: 10px;
  color: #333;
  font-weight: 500;
  text-decoration: none;
  background-color: #fff;
  border-radius: 8px;
  transition: background-color 0.3s, color 0.3s;
}

html[data-bs-theme="dark"] .side-menu .telegram-auth{
  background-color: initial;
  color:#FFF;
}

</style>
