//import axios from 'axios';
//import { API_URL } from '@/config.js';

const hashModule = {
  namespaced: true,
  state: {
    hash: '', // Initialize the hash variable
  },
  mutations: {
    setHash(state, hash) {
      state.hash = hash; // Mutation to set the value of hash
    },
  },
  actions: {
    updateHash({ commit }, hash) {
      commit('setHash', hash); // Action to update the value of hash
    },
    // saveUser({ state }) {
    //   // Save the user data to your backend or database
    //   // You can use an AJAX request or a suitable method for your backend
    //   // For example:
    //   axios
    //     .post(API_URL + '?action=save_user', { user: state.user })
    //     .then(response => {
    //       console.log(response);
    //       console.log('User data saved successfully');
    //     })
    //     .catch(error => {
    //       console.error('Error saving user data:', error);
    //     });
    // },
  },
  getters: {
    getHash(state) {
      return state.hash; // Getter to retrieve the value of hash
    },
  },
};

export default hashModule;
