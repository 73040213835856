// materialModule.js

import axiosConfig from '@/lib/axiosConfig';
import { API_URL } from '@/config.js';

const materialModule = {
  namespaced: true,
  state: {
    materials: [],
    materialItem: {},
    collectionMaterials: [],
    localOpenCondition: [], // Add localOpenCondition and localOpenByTestScore as part of the state
    localOpenByTestScore: [], // These arrays will store the open_condition and open_by_test_score values locally
    localOpenByPayment: [], // These arrays will store the open_condition and open_by_test_score values locally
  },
  mutations: {
    updateMaterialPriority(state, { id, priority }) {
      // Находим материал в collectionMaterials по id
      const materialIndex = state.collectionMaterials.findIndex(material => material.id === id);
      
      // Обновляем поле priority
      if (materialIndex !== -1) {
        state.collectionMaterials[materialIndex].priority = priority;
      }
    },

    setMaterialItem(state, material) {
      //console.log(material);
      state.materialItem = material;
    },

    setMaterials(state, materials) {
      //console.log(materials);
      state.materials = materials;
    },
    setCollectionMaterials(state, collection_materials) {
      state.collectionMaterials = collection_materials;
      // Loop through collection materials and update localOpenCondition and localOpenByTestScore
      state.localOpenCondition = collection_materials.map(material => material.open_condition || '0');
      state.localOpenByTestScore = collection_materials.map(material => material.open_by_test_score || '0');
      state.localOpenByPayment = collection_materials.map(material => material.open_by_payment || '0');
    },
    addToCollection(state, material) {
      state.collectionMaterials.push(material);
    },
    removeFromCollection(state, materialId) {
      const index = state.collectionMaterials.findIndex(
        (material) => material.id === materialId
      );
      if (index !== -1) {
        state.collectionMaterials.splice(index, 1);
      }
    },



    clearCollectionMaterials(state) {
      state.collectionMaterials = [];
    },
    reorderCollectionMaterials(state, { oldIndex, newIndex }) {
      const movedItem = state.collectionMaterials.splice(oldIndex, 1)[0];
      state.collectionMaterials.splice(newIndex, 0, movedItem);
    },   

    updateOpenCondition(state, { id, openCondition, openByTestScore, openByDate, openByPayment }) {
      let material = state.collectionMaterials.find(material => material.id === id);
      if (material) {
        material.open_condition = openCondition;
        material.open_by_test_score = openByTestScore;
        material.open_by_date = openByDate;
        material.open_by_payment = openByPayment;
      }
    }

  },
  actions: {
    getMaterialLogs({ commit }, materialId) {
      return axiosConfig
        .get(API_URL, {
          params: {
            action: 'get_material_logs',
            materialId: materialId,
          },
        })
        .then(response => {
          const logs = response.data.logs;
          commit('setMaterialLogs', logs); // Сохранение логов в состояние
          return logs;
        })
        .catch(error => {
          console.error(error);
          throw error;
        });
    },
        
    fetchMaterials({ commit }, payload) {
      var params = {'action': 'get_materials'};

      if (typeof payload !== 'undefined'){
        if (typeof payload.channelId !== 'undefined') params['channelId'] = payload.channelId;
        if (typeof payload.categoryId !== 'undefined') params['categoryId'] = payload.categoryId;
        if (typeof payload.query !== 'undefined') params['query'] = payload.query;
      }

      return axiosConfig
        .get(API_URL, {
          params: params
        })
        .then(response => {
          const materials = response.data.materials;
          commit('setMaterials', materials);
          return response.data;
        })
        .catch(error => {
          console.error(error);
          throw error;
        });
    },
    deleteMaterial({ state, commit }) {
      const { materialItem } = state;
      if (!materialItem || !materialItem.id) {
        throw new Error('Invalid material item');
      }

      console.log(state);
      return axiosConfig
        .post(API_URL, {
          params:{
            action: 'delete_material',
            materialId: materialItem.id
          }
        })
        .then(response => {
          console.log(response);
          commit('setMaterialItem', null); 
          return response.data;
        })
        .catch(error => {
          console.error(error);
          throw error;
        });
    },

    restartMaterial({ state, commit }) {
      const { materialItem } = state;
      if (!materialItem || !materialItem.id) {
        throw new Error('Invalid material item');
      }

      console.log(state);
      return axiosConfig
        .post(API_URL, {
          params:{
            action: 'restart_material',
            materialId: materialItem.id
          }
        })
        .then(response => {
          console.log(response);
          commit('setMaterialItem', null); 
          return response.data;
        })
        .catch(error => {
          console.error(error);
          throw error;
        });
    },

    saveMaterialCoverImage({ commit }, { materialId, coverImageForm }) {
      coverImageForm.append('action', 'save_material_cover_image');
      coverImageForm.append('materialId', materialId);
          
      return axiosConfig.post(API_URL, coverImageForm, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
        .then(response => {
          // Если обложка подборки успешно сохранена на сервере, обновляем данные о коллекции в хранилище
          const article = response.data.article;
          commit('setMaterialItem', article);
          return response.data;
        })
        .catch(error => {
          console.error('Error uploading cover image:', error);
          throw error;
        });
    },

    saveMaterialMedia({ commit }, { materialId, mediaForm, dontConvertMedia, verticalAlign, templateId }) {
      mediaForm.append('action', 'save_material_media');
      mediaForm.append('materialId', materialId);
      mediaForm.append('dontConvertMedia', dontConvertMedia);
      if (verticalAlign) mediaForm.append('verticalAlign', verticalAlign);
      if (templateId) mediaForm.append('templateId', templateId);

      
          
      return axiosConfig.post(API_URL, mediaForm, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
        .then(response => {
          const article = response.data.article;
          commit('setMaterialItem', article);
          return response.data;
        })
        .catch(error => {
          console.error('Error uploading cover image:', error);
          throw error;
        });
    },

    // eslint-disable-next-line
    deleteMaterialFile({ commit }, payload) {

      //console.log(state);
      return axiosConfig
        .post(API_URL, {
          params:{
            action: 'delete_material_file',
            materialId: payload.materialId,
            fileHash: payload.fileHash
          }
        })
        .then(response => {
          console.log(response);
          //commit('setMaterialItem', null); 
          return response.data;
        })
        .catch(error => {
          console.error(error);
          throw error;
        });
    },


    // eslint-disable-next-line
    saveMaterialFile({ commit }, { materialId, fileForm }) {
      fileForm.append('action', 'save_material_file');
      fileForm.append('materialId', materialId);
          
      return axiosConfig.post(API_URL, fileForm, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
        .then(response => {
          //const article = response.data.article;
          //commit('setMaterialItem', article);
          return response.data;
        })
        .catch(error => {
          console.error('Error uploading file:', error);
          throw error;
        });
    },    
    clearCollectionMaterials({ commit }) {
      commit('clearCollectionMaterials');
    },


    // eslint-disable-next-line
    updateCollectionMaterialsFromList({ state }, { collectionId, materialsList }) {
      return axiosConfig
        .post(API_URL, {
          params: {
            action: 'update_collection_materials_from_list',
            collectionId: collectionId,
            materialsList: materialsList,
          }
        })
        .then(response => {

          console.log(response.data);
          return response.data;
        })
        .catch(error => {
          console.error(error);
          throw error;
        });
    }, 
    
    updateCollectionMaterials({ state }, { collectionId }) {
      return axiosConfig
        .post(API_URL, {
          params: {
            action: 'update_collection_materials',
            collectionId: collectionId,
            materials: state.collectionMaterials,
          }
        })
        .then(response => {

          console.log(response.data);
          return response.data;
        })
        .catch(error => {
          console.error(error);
          throw error;
        });
    },
    removeCollectionFromMaterial({state}, collectionId) {
      console.log(collectionId);
      const index = state.materialItem.collections.findIndex(
        (collection) => collection.collection_id === collectionId
      );

      console.log(index);
      if (index !== -1) {
        // Вместо state.materialItem.splice(index, 1);
        // Используйте splice для удаления элемента из массива
        state.materialItem.collections.splice(index, 1);
      }
    },    
    fetchCollectionMaterials({ commit }, collectionId) {
      return axiosConfig
        .get(API_URL, {
          params: {
            action: 'get_collection_materials',
            collectionId: collectionId
          },
        })
        .then(response => {
          const collection_materials = response.data.collection_materials;
          commit('setCollectionMaterials', collection_materials);
          return response.data;
        })
        .catch(error => {
          console.error(error);
          throw error;
        });
    },

    saveMaterialSettings({ commit }, materialItem) {
      console.log(materialItem);
      return axiosConfig
        .post(API_URL, {
          params:{
            action: 'save_material_settings',
            materialItem: materialItem
          }
        })
        .then(response => {
          console.log(response);

          const material = response.data.material_item;
          commit('setMaterialItem', material);          
          return response.data;
        })
        .catch(error => {
          console.error(error);
          throw error;
        });
    },

    saveMaterialCategory({ commit }, materialItem) {
      console.log(materialItem);
      return axiosConfig
        .post(API_URL, {
          params:{
            action: 'save_material_category',
            materialItem: materialItem
          }
        })
        .then(response => {
          console.log(response);

          const material = response.data.material_item;
          commit('setMaterialItem', material);          
          return response.data;
        })
        .catch(error => {
          console.error(error);
          throw error;
        });
    },

    addMaterialToCollection({ commit }, material) {
      commit('addToCollection', material);
    },
    removeMaterialFromCollection({ commit }, materialId) {
      commit('removeFromCollection', materialId);
    },
    moveMaterialInCollection({ commit, dispatch }, payload) {
      console.log(payload);
      commit('reorderCollectionMaterials', payload);
      dispatch('updateCollectionMaterials', { collectionId: payload.collectionId }); 
    },
  }, 
  getters: {
    getMaterialItem(state) {
      return state.materialItem;
    },
    materials(state) {
      return state.materials;
    },
    collectionMaterials(state) {
      return state.collectionMaterials;
    },
  },
};

export default materialModule;
