<template>
  <footer class="footer py-3">
    <div class="container">
      <div class="row">
        <div class="col-md-12" v-html="configData.footer_text"></div>
        <div class="col-md-6 text-md-end">
          <ul class="list-inline">
            <!-- Ваши ссылки и элементы списка здесь -->
          </ul>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  computed: {
    ...mapGetters('app', ['configData']),
  },
};
</script>

<style scoped>
  .footer{
    margin-top: 10px;
    padding-top: 10px;
    border-top: 1px solid #EFEFEF;
    color:#999;
    text-align: center;
  }
</style>
