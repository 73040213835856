import axiosConfig from '@/lib/axiosConfig';
import { API_URL } from '@/config.js';

const segmentModule = {
  namespaced: true,
  state: {
    availableSegments: [],
    showSegmentModal: false,
    targetVideo: null,
    segmentPosition: null,
    segmentInsertIndex: null
  },
  mutations: {
    setAvailableSegments(state, segments) {
      state.availableSegments = segments;
    },
    setShowSegmentModal(state, show) {
      state.showSegmentModal = show;
    },
    setTargetVideo(state, video) {
      state.targetVideo = video;
    },
    setSegmentPosition(state, position) {
      state.segmentPosition = position;
    },
    setSegmentInsertIndex(state, index) {
      state.segmentInsertIndex = index;
    },
    incrementSegmentInsertIndex(state) {
      state.segmentInsertIndex += 1;
    },    
    resetSegmentInsertIndex(state) {
      state.segmentInsertIndex = null;
    },
    clearModalState(state) {
      state.targetVideo = null;
      state.segmentPosition = null;
      state.showSegmentModal = false;
    }
  },
  actions: {
    async fetchSegments({ commit }, { video, position }) {
      commit('setTargetVideo', video);
      commit('setSegmentPosition', position);
      try {
        const response = await axiosConfig.get(`${API_URL}`, {
          params: {
            action: 'narezka_get_segments',
            videoId: video.id,
            position: position
          }
        });
        commit('setAvailableSegments', response.data.result.segments);
        commit('setShowSegmentModal', true);
      } catch (error) {
        console.error('Error fetching segments:', error);
        throw error;
      }
    },
    closeSegmentModal({ commit }) {
      commit('clearModalState');
    },
    
  async saveSelectedSegment({ state, commit, rootState }, { segment, videoIndex }) {
  const targetVideo = rootState.article.articleTimecodeWords.videos[videoIndex];
  if (targetVideo) {
    let insertIndex = state.segmentInsertIndex;

    if (state.segmentPosition === 'before') {
      targetVideo.segments.splice(insertIndex, 0, segment);
      commit('incrementSegmentInsertIndex');
    } else {
      targetVideo.segments.splice(insertIndex + 1, 0, segment);
    }

    try {
      const response = await axiosConfig.post(`${API_URL}`, {
        params: {
          action: 'save_material_videos_segments',
          materialId: rootState.article.articleItem.id,
          videoId: targetVideo.id,
          video: targetVideo
        }
      });
      commit('narezka/updateVideo', { videoIndex, updatedVideo: response.data.updatedVideo }, { root: true });
    } catch (error) {
      console.error('Error saving segments:', error);
      throw error;
    }
  }
}
,

  async removeSelectedSegment({ commit, rootState }, { segment, videoIndex }) {
    const targetVideo = rootState.article.articleTimecodeWords.videos[videoIndex];
    if (targetVideo) {
      const segmentStart = segment.words[0]?.start;
      const segmentEnd = segment.words[segment.words.length - 1]?.end;

      const segmentIndex = targetVideo.segments.findIndex(s => 
        s.words[0]?.start === segmentStart && s.words[s.words.length - 1]?.end === segmentEnd
      );

      if (segmentIndex !== -1) {
        targetVideo.segments.splice(segmentIndex, 1);
      }

      try {
        const response = await axiosConfig.post(`${API_URL}`, {
          params: {
            action: 'save_material_videos_segments',
            materialId: rootState.article.articleItem.id,
            videoId: targetVideo.id,
            video: targetVideo
          }
        });
        // Обновление только измененного видео
        commit('narezka/updateVideo', { videoIndex, updatedVideo: response.data.updatedVideo }, { root: true });
      } catch (error) {
        console.error('Error removing segments:', error);
        throw error;
      }
    }
    // Убираем закрытие модального окна
    //commit('clearModalState');
  }
  },
  getters: {
    availableSegments(state) {
      return state.availableSegments;
    },
    showSegmentModal(state) {
      return state.showSegmentModal;
    }
  }
};

export default segmentModule;
