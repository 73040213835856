// store/modules/appModule.js

import axiosConfig from '@/lib/axiosConfig';
import { API_URL } from '@/config.js';

const state = {
  isMobile: false,
  isTablet: false,
  configData: {},
  isPreview: false, // Режим превью
  darkMode: false, 
  chatbotName: '',
  menuVisible: true, // Добавленное состояние для видимости бокового меню
};

const mutations = {
  SET_IS_MOBILE(state, value) {
    state.isMobile = value;
  },
  SET_IS_TABLET(state, value) {
    state.isTablet = value;
  },
  SET_CONFIG_DATA(state, configData) {
    state.configData = configData;
  },
  SET_CHATBOT_NAME(state, value) {
    state.chatbotName = value;
  },
  SET_PREVIEW(state, { value }) {
    state.isPreview = value;
    if (value) {
      document.documentElement.classList.add('preview');
    } else {
      document.documentElement.classList.remove('preview');
    }
  },
  SET_DARK_MODE(state, value) {
    state.darkMode = value;
    const htmlElement = document.documentElement;
    if (value) {
      htmlElement.setAttribute('data-bs-theme', 'dark');
    } else {
      htmlElement.removeAttribute('data-bs-theme');
    }
  },
  SET_MENU_VISIBLE(state, value) {
    state.menuVisible = value;
  }
};

const actions = {
  setIsMobile({ commit }, value) {
    commit('SET_IS_MOBILE', value);
  },
  setIsTablet({ commit }, value) {
    commit('SET_IS_TABLET', value);
  },
  fetchConfigData({ commit }) {
    return axiosConfig
      .get(API_URL, {
        params: {
          action: 'get_config_data',
        },
      })
      .then(response => {
        const config = response.data.config_data;
        commit('SET_CONFIG_DATA', config);
      })
      .catch(error => {
        console.error(error);
        throw error;
      });
  },

  // eslint-disable-next-line
  sendErrorReport({ state }, payload) {
    const formData = new FormData();
    formData.append('text', payload.selectedText);
    formData.append('context', payload.context);
    formData.append('info', payload.additionalInfo);
    formData.append('page', payload.page);

    return axiosConfig
      .post(`${API_URL}?action=report_error`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      .then(response => {
        return response.data; // Возвращаем ответ от сервера
      })
      .catch(error => {
        console.error("Ошибка при отправке отчета об ошибке:", error);
        throw error;
      });
  },
  setPreview({ commit, dispatch }, value) {
    commit('SET_PREVIEW', { value });
    dispatch('setDarkMode', value);
  },
  setDarkMode({ commit }, value) {
    commit('SET_DARK_MODE', value);
  },
  setChatbotName({ commit }, value) {
    commit('SET_CHATBOT_NAME', value);
  },
  setMenuVisible({ commit, dispatch }, value) {
    commit('SET_MENU_VISIBLE', value);
    dispatch('updateBodyClass', value); // Обновляем классы на body при изменении состояния меню
  },
  toggleMenuVisible({ commit, state, dispatch }) {
    commit('SET_MENU_VISIBLE', !state.menuVisible);
    dispatch('updateBodyClass', state.menuVisible); // Обновляем классы на body при переключении
  },
  updateBodyClass({ state }) {
    const body = document.body;
    if (state.menuVisible) {
      body.classList.add('menu-open');
      body.classList.remove('menu-closed');
    } else {
      body.classList.add('menu-closed');
      body.classList.remove('menu-open');
    }
  },
};

const getters = {
  isMobile: (state) => state.isMobile,
  isTablet: (state) => state.isTablet,
  configData: (state) => state.configData,
  isPreview: (state) => state.isPreview,
  darkMode: (state) => state.darkMode,
  chatbotName: (state) => state.chatbotName,
  menuVisible: (state) => state.menuVisible, // Геттер для доступа к состоянию видимости меню
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
